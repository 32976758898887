export const dataMCMIyDPELE2024 = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "SEP",
    nameBoletin: "Primer Informe del 24 al 30 de septiembre",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Segundo informe del 01 al 15 de octubre",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Tercer informe del 16 al 31 de octubre",
  },
];

export const dataRyTPELE2024 = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Primer Reporte Semanal del 15 al 22 de octubre",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Primer Informe Quincenal del 15 al 24 de octubre",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Segundo Reporte Semanal del 23 al 24 de octubre",
  },
  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Tercer Reporte Semanal del 06 al 13 de noviembre",
  },
];
