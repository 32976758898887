import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import { FilterComponent, useSimpleTableConfig } from "../../../constants";
import {
  dataMCMIyDPELE2024,
  dataRyTPELE2024,
} from "../../../data/2024/dataMonitoreosPELE2024";

const baseUrl = "https://itetlax.org.mx/assets/pdf/monitoreos/PELE2024/";

const PdfLink = ({ url, folder }) => {
  const fullUrl = `${baseUrl}${folder}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCellMCMIyD = ({ cell }) => {
  const url = String(cell.getValue());
  return <PdfLink url={url} folder={"MCMIyD"} />;
};

const renderCellRyT = ({ cell }) => {
  const url = String(cell.getValue());
  return <PdfLink url={url} folder={"RyT"} />;
};

const MonitoreosPELE2024 = () => {
  useEffect(() => {
    document.title = `Monitoreos PELE 2024`;
  }, []);

  const columnsMCMIyD = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "NO.",
      },
      {
        accessorKey: "monthBoletin",
        header: "MES",
        Filter: FilterComponent,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
      },
      {
        accessorFn: (row) => `${row.id}.pdf`,
        id: "pdf",
        header: "",
        Cell: renderCellMCMIyD,
      },
    ],
    []
  );

  const columnsRyT = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "NO.",
      },
      {
        accessorKey: "monthBoletin",
        header: "MES",
        Filter: FilterComponent,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
      },
      {
        accessorFn: (row) => `${row.id}.pdf`,
        id: "pdf",
        header: "",
        Cell: renderCellRyT,
      },
    ],
    []
  );
  const tableMCMIyDPELE2024 = useSimpleTableConfig(
    dataMCMIyDPELE2024,
    columnsMCMIyD
  );
  const tableRyTELE2024 = useSimpleTableConfig(dataRyTPELE2024, columnsRyT);

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Comunicación Social", url: "/ComunicacionSocial" },
          { label: "Monitoreos", url: "/Monitoreo" },
          { label: `Monitoreos PELE 2024` },
        ]}
      />
      <TitlePages title="Comunicación Social" subTitle="Monitoreos PELE 2024" />
      <br />
      <br />
      <TitlePages
        title=""
        subTitle="Monitoreos de Medios de Comunicación Masiva, impresos y Digitales"
      />
      <MaterialReactTable table={tableMCMIyDPELE2024} />
      <TitlePages
        title=""
        subTitle="Monitoreos de Espacios que difunden noticias en Radio y Televisión"
      />
      <MaterialReactTable table={tableRyTELE2024} />
      <TitlePages title="" subTitle="Informe Único de Precampañas" />
      <p>
        Informe único de Precampañas con Perspectiva de Género a Medios de
        Comunicación Masiva Impresos, Digitales y Programas que difunden
        noticias por Radio y Televisión.
      </p>
      <PdfLink url={"1.pdf"} folder={"PG"} />
    </>
  );
};

export default MonitoreosPELE2024;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
  folder: PropTypes.string.isRequired,
};
