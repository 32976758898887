const baseUrl = "https://itetlax.org.mx/assets/img/";

export const modalsInitData = [
  // {
  //     id: 1,
  //     imgLink: "https://itetlax.org.mx/assets/img/imgPopup/consultaconvo.jpg",
  //     url: "",
  //     linkToo: "/ConvocatoriasApartado",
  //   },

  {
    id: 1,
    imgLink: `${baseUrl}imgPopup/conoceles.jpg`,
    url: `https://conocelestlaxcala2024.org.mx/`,
    linkToo: "",
  },
  // {
  //    id: 3,
  //    imgLink: `${baseUrl}imgPopup/sesionextraordinaria.jpg`,
  //    url: `${baseUrl}imgPopup/sesionextraordinaria.jpg`,
  //    linkToo: "",
  // },
];
